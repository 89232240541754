<template>
  <div v-if="isLoggedIn" class="main">
    <NavBar />
    <v-data-table
      :page="page + 1"
      :pageCount="numberOfPages"
      :server-items-length="totalProducts"
      :options.sync="options"
      :loading="loading"
      :headers="headers"
      :items="allProducts"
      :key="allProducts.id"
      show-expand
      class="tableWrapper"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="parent">
            <div class="prod">
              <h4>Opis oglasa:</h4>
              {{ item.description }}
              <h4>Cena fiksna:</h4>
              {{ item.isPriceFixed == true ? "Da" : "Ne" }}
              <h4>Zamena:</h4>
              {{ item.isExchangePossible == true ? "Moguća zamena" : "Bez zamene" }}
            </div>
            <div class="dBlock">
              <h4>Podaci prodavca:</h4>
              {{ item.user.firstName }}
              {{ item.user.lastName }}<br />
              {{ item.user.email }} <br />
              {{ item.phoneNumber }}
              <h4>Mogućnost plaćanja:</h4>
              {{
                item.paymentOption == 0
                  ? "Postoji cena"
                  : item.paymentOption == 1
                  ? "Dogovor"
                  : item.paymentOption == 2
                  ? "Kontakt"
                  : item.paymentOption == 3
                  ? "Pozvati"
                  : "Besplatno"
              }}
              <h4>Razlog prijave</h4>
              {{ item.complains[0].message }}
              <h4><a :href="`https:/www.poljopijaca.rs/oglas/${item.id}`"> Prikaži oglas </a></h4>
            </div>
          </div>
        </td>
      </template>
      <template v-slot:item.mainImgName="{ item }">
        <img :src="item.mainImgName ? `https://api.poljopijaca.rs/${item.mainImgName}` : require('../../../Assets/noimg.webp')" style="width: 60px; height: 60px" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Prijavljeni oglasi</v-toolbar-title>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-snackbar v-model="productDeletedSnackbar" color="green" bottom> Oglas je uspešno obrisan </v-snackbar>
    <DeleteProduct v-model="showDeleteDialog" :product="deleteProduct" @productIsDeleted="productIsDeleted" />
  </div>
</template>

<script>
import axios from "axios";
import DeleteProduct from "./DeleteProduct.vue";
import NavBar from "../../../Components/NavBar.vue";

export default {
  components: {
    NavBar,
    DeleteProduct,
  },
  data() {
    return {
      showDeleteDialog: false,
      productDeletedSnackbar: false,
      page: 0,
      totalProducts: 0,
      numberOfPages: 0,
      options: {},
      dateFrom: "",
      dateTo: "",
      priceFrom: 0,
      priceTo: 0,
      subCategoryId: 0,
      userId: 0,
      loading: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Slika", align: "start", value: "mainImgName" },
        { text: "Podkategorija", align: "start", value: "subCategory.name" },
        { text: "Naziv oglasa", align: "start", value: "title" },
        { text: "Prodavac", align: "start", value: "userFullName" },
        { text: "Lokacija", align: "start", value: "location" },
        { text: "Cena (rsd)", align: "start", value: "price" },
        { text: "Razlog prijave", align: "start", value: "complains[0].message" },
        { text: "Uredi", value: "actions", sortable: false },
      ],
      allProducts: [],
      editedIndex: -1,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
  },
  watch: {
    options: {
      handler() {
        this.getProducts();
      },
      deep: true,
    },
  },
  methods: {
    async getProducts() {
      this.loading = true;
      const products = await axios.get("Product/GetAllInappropriate");
      const productsObject = products.data?.map((el) => {
        return {
          ...el,
          userFullName: `${el.user.firstName} ${el.user.lastName}`,
        };
      });
      this.allProducts = productsObject;
      this.loading = false;
      this.totalProducts = productsObject.length;
    },
    deleteItem(item) {
      this.deleteProduct = item;
      this.showDeleteDialog = true;
    },
    productIsDeleted() {
      this.getProducts();
      this.productDeletedSnackbar = true;
      setTimeout(() => {
        this.productDeletedSnackbar = false;
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  margin-bottom: 85px;
}
.tableWrapper {
  width: 1440px;
  margin: 2rem auto;
}
.parent {
  display: flex;
}
.prod {
  width: 50%;
  padding: 0.1rem;
  margin-top: 0.1rem;
}
.dBlock {
  width: 50%;
}
</style>
